.catalog-block {

  $block-name:                &; // #{$block-name}__element

  &__title {
    margin-bottom: 0.75em;
  }

  &__list {

    li + li {
      margin-top: 0.5em;
    }
  }

  &__item {

    a {
      font-weight: 500;
      color: $text-color;

      &:hover,
      &:focus {
        color: $main-color;
        text-decoration: none;
      }
    }
  }
}
