.more-news-block {
  padding: 25px 15px;
  margin-bottom: 25px;
  background-color: $white;
  box-shadow: $shadow;

  @media (min-width: $screen-md) {
    margin-bottom: 30px;
  }

  @media (min-width: $screen-lg) {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 25px;
  }

  $block-name:                &; // #{$block-name}__element

  &__list {

    li + li {
      margin-top: 30px;
    }
  }

  &__item {
    position: relative;

    &:last-child {
      &::after {
        display: none;
      }
    }

    &::after {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      width: 100%;
      height: 2px;
      background-color: $body-bg;
    }
  }

  &__link {
    display: inline-block;
    vertical-align: top;

    &:hover,
    &:focus {
      color: $main-color;
      text-decoration: none;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 1.3em;
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: $font-size--h5-mob;
  }
}
