$gray-lightest:               hsl(0, 0%, 90%);
$border-color:                hsl(0, 0%, 60%) !default;

$line-height:                 1.375em !default;

$field-padding-vertical:      0.3em !default;
$field-padding-horizontal:    0.7em !default;

$border-radius:               3px !default;

.field-file {

  $block-name:                &; // #{$block-name}__element

  display: block;
  margin-bottom: 1.5em;

  &__name {
    @include field-name;
    margin-bottom: 0;
  }

  &__input-wrap {
    display: block;
    position: relative;
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;

    &:active ~ .field-file__name-text,
    &:focus ~ .field-file__name-text {
      @include field-focus;
      color: $white;
      background-color: $main-color;

      &:after {
        background: svg-load('../blocks/sprite-svg/svg/upload.svg', fill=$white);
      }
    }
  }

  &__name-text {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 12px;
    padding-left: $field-padding-horizontal;
    padding-right: $field-padding-horizontal;
    margin-bottom: 0.65em;
    text-align: center !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // border: 2px solid $border-color;
    border-radius: $border-radius;
    background-color: $body-bg;
    cursor: pointer;

    &:before {
      content: attr(data-button-text);
      display: block;
      width: 100%;
      padding: 12px 12px 10px;
      padding-left: 32px;
      font-weight: 500;
      line-height: $line-height;
      text-align: left;
      //background-color: $gray-lightest;
      // margin-right: $field-padding-horizontal;
      // border-right: 1px solid $border-color;
      // border-top-left-radius: $border-radius;
      // border-bottom-left-radius: $border-radius;
    }

    &:after {
      display: block;
      content: "";
      position: absolute;
      top: 11px;
      left: $field-padding-horizontal;
      width: 18px;
      height: 18px;
      //background-color: red;
      background: svg-load('../blocks/sprite-svg/svg/upload.svg', fill=$text-muted, stroke=rgba(255, 255, 255, 0));
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &.notempty,
    &:hover,
    &:focus {
      color: $white;
      background-color: $main-color; // как у обычных кнопок

      &:after {
        background: svg-load('../blocks/sprite-svg/svg/upload.svg', fill=$white, stroke=rgba(255, 255, 255, 0));
      }
    }
  }

  &__help-text {
    @include field-help-text;
  }
}
