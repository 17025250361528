.product-slider {
  margin-bottom: 25px;

  $block-name:                &; // #{$block-name}__element

  .owl-nav {
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: $screen-xl) {
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  &__slider-col {
    position: relative;
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 12);
    @include col(xl, 4);
    margin-bottom: 25px;

    @media (min-width: $screen-xl) {
      margin-bottom: 0;
    }
  }

  &__card-col {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 12);
    @include col(xl, 8);
    //margin-bottom: 25px;
  }

  &__container {
    height: 100%;
    background-color: $white;
    box-shadow: $shadow;
  }

  &__img {
    max-width: 405px;
    margin-left: auto;
    margin-right: auto;

    img {
      height: 100%;
      max-width: 405px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__link {
    display: block;
    padding: 15px;
    cursor: zoom-in;
  }

  &__card {

    .row {
      @media (min-width: $screen-xl) {
        height: 100%;
      }
    }

    @media (min-width: $screen-xl) {
      height: 100%;
    }
  }

  &__card-descr-col {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 8);
    margin-bottom: 25px;

    @media (min-width: $screen-md) {
      margin-bottom: 0;
    }
  }

  &__card-buy-col {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 4);
  }

  &__presentation {
    height: 100%;
    padding: 24px 10px;
    background-color: $white;
    box-shadow: $shadow;

    @media (min-width: $screen-md) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 24px 25px;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: 25px;
  }

  &__list {
    margin-bottom: 0;

    @media (min-width: $screen-md) {
      margin-top: auto;
    }

    dt {
      margin: 0;
    }
  }

  &__list-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 15px;

    @media (min-width: $screen-md) {
      margin-bottom: 25px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__name {
    width: calc(50% - 10px);

    @media (min-width: $screen-md) {
      width: calc(60% - 10px);
    }
  }

  &__value {
    width: calc(50% - 10px);
    margin-left: 0;
    color: $text-muted;

    @media (min-width: $screen-md) {
      width: calc(40% - 10px);
    }

    &--colored {
      font-weight: 500;
      color: $main-color;
    }
  }

  &__purchase {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 0 0;
    background-color: $white;
    box-shadow: $shadow;

    @media (min-width: $screen-xl) {
      height: 100%;
    }
  }

  &__logo {
    align-self: center;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    margin-bottom: 35px;

    @media (min-width: $screen-md) {
      margin-bottom: 25px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 55px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__price-block {
    margin-bottom: 25px;
    text-align: center;
  }

  &__price-new {
    margin-bottom: 10px;
  }

  &__price-old {
    font-size: 16px;
    text-decoration: line-through;
    color: $text-muted;
  }

  &__controls {

    .field-num__input-and-btns {
      max-width: 180px;
      margin-left: auto;
      margin-right: auto;
    }

    .field-num__btn {
      width: 32px;
      height: 32px;
      border: 2px solid $body-bg;
    }

    .field-num__input {
      width: 180px;
    }
  }

  &__favorits-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    color: $text-muted;

    @media (min-width: $screen-md) {
      margin-bottom: 15px;
    }

    span {
      margin-left: 10px;
      color: inherit;
    }
  }

  &__btn-favorits {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;

    svg {
      position: static;
      display: inline-block;
      vertical-align: middle;
      transform: none;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__submit {
    width: 100%;
    height: auto;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
    font-weight: 500;
    color: $white;
    background-color: $main-color;
    //border: 2px solid $main-color;

    span {
      position: relative;
      top: 1px;
    }

    &::before {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      content: "";
      width: 18px;
      height: 18px;
      background: svg-load('../blocks/sprite-svg/svg/basket.svg', fill=$white);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &:hover,
    &:focus {
      background-color: $main-color--hover;
    }
  }

  &__special-mark {
    z-index: 1;
    top: 30px;
    left: 10px;
  }
}
