.delivery-and-payment {

  $block-name:                &; // #{$block-name}__element

  &__title {
    padding: 25px 0;
    margin: 0;
    border-bottom: 2px solid $body-bg;
  }

  &__col-text {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 12);
    @include col(lg, 7);

    margin-bottom: 25px;
    background-color: $white;
    box-shadow: $shadow;

    @media (min-width: $screen-md) {
      background: none;
      box-shadow: none;
    }
  }

  &__col-aside {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 12);
    @include col(lg, 5);
    padding-left: 0;
    padding-right: 0;
  }

  &__text {

    @media (min-width: $screen-md) {
      padding-left: 20px;
      padding-right: 20px;
      background-color: $white;
      box-shadow: $shadow;
    }

    @media (min-width: $screen-lg) {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media (min-width: $screen-xl) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__content {
    padding: 25px 0;

    ol,
    ul {
      list-style: none;
      padding-left: 0;
    }

    li {
      margin-top: 15px;
    }
  }

  &__cars {

    @media (min-width: $screen-md) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &__item {
    margin: 0 0 25px 0;

    @media (min-width: $screen-md) {
      width: calc(50% - 12.5px);
    }

    @media (min-width: $screen-lg) {
      width: 100%;
    }
  }

  &__cars-title {
    padding: 0 10px;
    margin-bottom: 0;

    @media (min-width: $screen-md) {
      padding: 0;
    }
  }

  &__img {
    margin-bottom: 15px;
    background-color: $white;
    box-shadow: $shadow;

    img {
      object-fit: contain;
      object-position: center;
    }
  }
}
