.brands-aside {

  $block-name:                &; // #{$block-name}__element

  &__col {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(sm, 6);
    @include col(md, 4);
    @include col(lg, 12);
    margin-bottom: 15px;

    @media (min-width: $screen-md) {
      margin-bottom: 25px;
    }
  }

  &__title {

    @media (min-width: $screen-lg) {
      margin-top: 0;
    }
  }
}
