/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Не пишите сюда ничего вручную, все такие правки будут потеряны.
 * Читайте ./README.md для понимания.
 */

@import './src/scss/variables.scss';
@import './src/scss/mixins.scss';
@import './src/scss/functions.scss';
@import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
@import '../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css';
@import './src/blocks/mmenu/mmenu.scss';
@import './src/blocks/accordion/accordion.scss';
@import './src/blocks/breadcrumbs/breadcrumbs.scss';
@import './src/blocks/btn/btn.scss';
@import './src/blocks/burger/burger.scss';
@import './src/blocks/close/close.scss';
@import './src/blocks/dropdown/dropdown.scss';
@import './src/blocks/embed-responsive/embed-responsive.scss';
@import './src/blocks/field-text/field-text.scss';
@import './src/blocks/field-text/field-text--error.scss';
@import './src/blocks/field-checkbox/field-checkbox.scss';
@import './src/blocks/field-checkbox/field-checkbox__input-wrap--error.scss';
@import './src/blocks/field-radio/field-radio.scss';
@import './src/blocks/field-radio/field-radio__input-wrap--error.scss';
@import './src/blocks/field-toggler/field-toggler.scss';
@import './src/blocks/field-file/field-file.scss';
@import './src/blocks/field-file/field-file--error.scss';
@import './src/blocks/field-range/field-range.scss';
@import './src/blocks/field-search/field-search.scss';
@import './src/blocks/field-select/field-select.scss';
@import './src/blocks/field-select/field-select--error.scss';
@import './src/blocks/field-actions/field-actions.scss';
@import './src/blocks/field-num/field-num.scss';
@import './src/blocks/fields-group/fields-group.scss';
@import './src/blocks/form/form.scss';
@import './src/blocks/is-mobile/is-mobile.scss';
@import './src/blocks/loader/loader.scss';
@import './src/blocks/logo/logo.scss';
@import './src/blocks/main-nav/main-nav.scss';
@import './src/blocks/mmenu/mmenu.scss';
@import './src/blocks/modal/modal.scss';
@import './src/blocks/nouislider/nouislider.scss';
@import './src/blocks/object-fit-polyfill/object-fit-polyfill.scss';
@import './src/blocks/owl-carousel/owl-carousel.scss';
@import './src/blocks/page/page.scss';
@import './src/blocks/page-footer/page-footer.scss';
@import './src/blocks/page-header/page-header.scss';
@import './src/blocks/pagination/pagination.scss';
@import './src/blocks/scroll-link/scroll-link.scss';
@import './src/blocks/sprite-svg/sprite-svg.scss';
@import './src/blocks/table/table.scss';
@import './src/blocks/table-mobile-scroll/table-mobile-scroll.scss';
@import './src/blocks/table-responsive/table-responsive.scss';
@import './src/blocks/typo/typo.scss';
@import './src/blocks/social-contact/social-contact.scss';
@import './src/blocks/lang-switcher/lang-switcher.scss';
@import './src/blocks/catalog-menu/catalog-menu.scss';
@import './src/blocks/main-menu/main-menu.scss';
@import './src/blocks/main-slider/main-slider.scss';
@import './src/blocks/features-block/features-block.scss';
@import './src/blocks/category-block/category-block.scss';
@import './src/blocks/article-block/article-block.scss';
@import './src/blocks/order-form/order-form.scss';
@import './src/blocks/seo-text-block/seo-text-block.scss';
@import './src/blocks/subscription-block/subscription-block.scss';
@import './src/blocks/social-block/social-block.scss';
@import './src/blocks/copyrights/copyrights.scss';
@import './src/blocks/phones-modal/phones-modal.scss';
@import './src/blocks/service-menu/service-menu.scss';
@import './src/blocks/search-form/search-form.scss';
@import './src/blocks/publishedon/publishedon.scss';
@import './src/blocks/catalog-block/catalog-block.scss';
@import './src/blocks/sf-menu/sf-menu.scss';
@import './src/blocks/category-item/category-item.scss';
@import './src/blocks/aside-nav/aside-nav.scss';
@import './src/blocks/brand-card/brand-card.scss';
@import './src/blocks/brand-intro/brand-intro.scss';
@import './src/blocks/products-container/products-container.scss';
@import './src/blocks/product-card/product-card.scss';
@import './src/blocks/filter-controls-block/filter-controls-block.scss';
@import './src/blocks/more-news-block/more-news-block.scss';
@import './src/blocks/article/article.scss';
@import './src/blocks/social-share/social-share.scss';
@import './src/blocks/brands-logo-block/brands-logo-block.scss';
@import './src/blocks/prod-filters-block/prod-filters-block.scss';
@import './src/blocks/product-slider/product-slider.scss';
@import './src/blocks/product-tabs/product-tabs.scss';
@import './src/blocks/tabs/tabs.scss';
@import './src/blocks/special-offer-card/special-offer-card.scss';
@import './src/blocks/special-offer-item/special-offer-item.scss';
@import './src/blocks/contacts-block/contacts-block.scss';
@import './src/blocks/map/map.scss';
@import './src/blocks/about/about.scss';
@import './src/blocks/delivery-and-payment/delivery-and-payment.scss';
@import './src/blocks/basket/basket.scss';
@import './src/blocks/checkout/checkout.scss';
@import './src/blocks/checkout-confirmation/checkout-confirmation.scss';
@import './src/blocks/calculator-card/calculator-card.scss';
@import './src/blocks/calculator-form/calculator-form.scss';
@import './src/blocks/calculator-item/calculator-item.scss';
@import './src/blocks/brands-aside/brands-aside.scss';
@import './src/blocks/login-form/login-form.scss';
@import './src/blocks/login-social/login-social.scss';
@import './src/blocks/personal-area/personal-area.scss';
@import './src/blocks/page-404/page-404.scss';
@import './src/blocks/registry-form/registry-form.scss';
@import './src/blocks/faq/faq.scss';
@import './src/blocks/rating/rating.scss';
@import './src/blocks/rating-show/rating-show.scss';
@import './src/blocks/review/review.scss';
@import './src/blocks/review-form/review-form.scss';
@import './src/scss/print.scss';
