.page-header {
  position: relative;
  z-index: 5;
  //margin-bottom: 20px;
  box-shadow: $shadow--header;

/*@media (min-width: $screen-md) {
  margin-bottom: 25px;
} */

  $block-name:                &; // #{$block-name}__element

  .dropdown-menu {
    min-width: 100%;
    padding: 0;
    margin-top: 0;
    border-bottom: 0;
  }

  .phones-modal__link {

    @media (min-width: $screen-xl) {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  &__top {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $header-top-bg;
  }

  &__main {
    background-color: $white;
  }

  &__logo-col {
    @include col(xs, 3, $grid-columns, 20px);
    @include col(md, 3);
    @include col(xl, 2);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    aling-items: center;
    margin: 5px 0;
  }

  &__btns-col {
    @include col(xs, 9, $grid-columns, 20px);
    @include col(md, 9);
    @include col(xl, 10);
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    aling-items: center;
  }

  &__search-col {
    @include col(xs, 12, $grid-columns, 20px);

    @media (min-width: $screen-md) {
      display: none;
    }
  }

  &__btn-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: $screen-md) {
      justify-content: space-between;
      padding-top: 24px;
      padding-bottom: 24px;
    }

    @media (min-width: $screen-xl) {
      align-items: flex-start;
      padding-top: 14px;
      padding-bottom: 14px;
    }

    button[data-target="#phones-modal"] {
      margin-left: 0;

      @media (min-width: $screen-xl) {
        display: none;
      }
    }

    &--search {
      position: relative;

      .search-form {
        margin-left: 0;
      }
    }

    & > * {
      margin-left: 18px;
    }
  }

  &__tel-wrapper {
    display: none;

    @media (min-width: $screen-xl) {
      display: block;
      margin-left: 30px;
      margin-right: 10px;
    }

    .phones-modal__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      max-width: 335px;
    }

    .phones-modal__worktime {
      margin-top: 5px;
      font-weight: 500;
    }
  }

  &__search-wrapper {
    display: none;

    @media (min-width: $screen-md) {
      position: relative;

      display: block;
      flex: 1 1;
      width: 340px;
      //max-width: 340px;
      margin-right: auto;

      /* & .btn {
        border: none !important;
      } */
    }
  }

  &__search-result-wrapper {
    position: absolute;
    top: calc(100% + 10px);
    left: 0;
    right: 0;
    width: 100%;
    max-height: 300px;
    background-color: $white;
    box-shadow: 2px 2px 4px rgba(55, 57, 58, 0.15);
    overflow-y: auto;

    @media (min-width: $screen-xs) and (orientation: landscape) {
      max-height: 145px;
    }

    @media (min-width: 375px) and (orientation: landscape) {
      max-height: 195px;
    }

    @media (min-width: 414px) and (orientation: landscape) {
      max-height: 230px;
    }

    &--mobile {
      top: 100%;
      margin-left: 0;


      .field-search__res-name {
        flex-basis: 55%;
        margin-right: 10px;

        @media (min-width: $screen-sm) {
          flex-basis: 65%;
          margin-right: 12px;
        }
      }
    }
  }

  &__basket-info {
    display: none;

    @media (min-width: $screen-xl) {
      display: inline-flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  &__total-price,
  &__total-quantity {
    font-size: $font-size--mob;
    font-weight: 700;
  }

  &__total-price {
    position: relative;

    &::after {
      display: block;
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $body-bg;
    }
  }

  &__total-quantity {
    margin-top: 10px;
    color: $main-color;
  }

  &__bottom {
    background: linear-gradient(to right, $main-color, $main-color 50%, $second-color 50%, $second-color);

    @media (min-width: $screen-xl) {
      //background: linear-gradient(to right, $main-color, $main-color 50%, $second-color 50%, $second-color);
      background: $second-color;
    }
  }

  &__catalog-menu {
    @include col(xs, 8, $grid-columns, 20px);
    @include col(lg, 4);
    @include col(xl, 3);
  }

  &__main-menu {
    @include col(xs, 4, $grid-columns, 20px);
    @include col(lg, 8);
    @include col(xl, 9);
    background-color: $second-color;

    @media (min-width: $menu-desktop-width) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  &__catalog-menu-toggler,
  &__main-menu-toggler {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: $font-size--mob;
    font-weight: 700;
    white-space: nowrap;
    color: $white;
    cursor: pointer;
    user-select: none;

    @media (min-width: $screen-sm) {
      font-size: $font-size;
    }

    &:hover,
    &:focus {
      color: $white;
    }
  }

  &__catalog-menu-toggler {
    justify-content: center;
    background-color: $main-color;

    span {
      margin-left: 20px;
    }

    svg {
      display: block;
      width: 22px;
      height: 22px;
    }

    @media (min-width: $screen-xl) {
      display: none;
    }
  }

  &__main-menu-toggler {
    justify-content: flex-end;
    background-color: $second-color;

    @media (min-width: $menu-desktop-width) {
      display: none;
    }

    .burger {
      margin-left: 20px;
      span,
      span::before,
      span::after {
        background-color: currentColor;
      }
    }
  }

  &__catalog-menu-wrapper {
    display: none;

    .dropdown-toggle::after {
      display: none;
    }

    #{$block-name}__catalog-menu-toggler {

      @media (min-width: $screen-xl) {
        display: flex;
      }
    }

    @media (min-width: $screen-xl) {
      display: block;
    }
  }
}
