.basket {
  margin-bottom: 35px;

  @media (min-width: $screen-md) {
    margin-bottom: 50px;
  }

  @media (min-width: $screen-xl) {
    margin-bottom: 90px;
  }

  $block-name:                &; // #{$block-name}__element

  &__title {

  }

  &__title-quant {
    margin-left: 20px;
    font-weight: 400;
    color: $text-muted;

    & > span {
      font-weight: 700;
    }
  }

  &__table {

    @media (min-width: $screen-xl) {
      margin-bottom: 25px;
      background-color: $white;
      box-shadow: $shadow;
    }
  }

  &__table-header {

    th {
      @media (min-width: $screen-xl) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  &__table-title {

    @media (min-width: $screen-xl) {
      padding-left: 15px;
    }

    &:first-child {
      padding-left: 45px;
    }
  }

  &__table-body {

  }

  &__table-row {
    position: relative;
    padding: 14px 12px 12px;
    margin-bottom: 25px;
    background-color: $white;
    border: none !important;
    box-shadow: $shadow;

    @media (min-width: $screen-md) {
      padding: 14px 20px 12px;
    }

    @media (min-width: $screen-xl) {
      padding: 0;
      box-shadow: none;
    }
  }

  &__table-cell {
    margin-bottom: 15px;

    @media (min-width: $screen-xl) {
      display: table-cell !important;
      padding-left: 12.5px;
      padding-right: 12.5px;
    }

    .field-num {
      margin-bottom: 0;
    }

    &--number {
      display: none !important;

      @media (min-width: $screen-xl) {
        display: table-cell !important;
        min-width: 20px;
        padding-left: 50px;
        padding-right: 10px;
      }
    }

    &--photo {

      @media (min-width: $screen-xl) {
        padding: 15px 12.5px;
      }
    }

    &--title {

      h5 {
        width: 100%;

        @media (min-width: $screen-xl) {
          margin: 0;
        }
      }
    }

    &--close {
      display: inline-block !important;
      vertical-align: top;
      width: auto !important;
      position: absolute;
      top: 15px;
      right: 15px;

      @media (min-width: $screen-xl) {
        position: static;
        padding-right: 40px;
      }
    }
  }

  &__table-cell:not(#{$block-name}__table-cell--number),
  &__table-cell:not(#{$block-name}__table-cell--photo),
  &__table-cell:not(#{$block-name}__table-cell--title),
  &__table-cell:not(#{$block-name}__table-cell--close) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (min-width: $screen-xl) {
      display: table-cell !important;
      vertical-align: middle;
    }

    #{$block-name}__table-sum,
    #{$block-name}__table-price {
      margin-left: auto;
      margin-right: 10px;
    }
  }

  &__close-btn {
    padding: 7px;
    color: $text-muted;
    background-color: $white;
    box-shadow: $shadow;
    border: none;

    .close {
      color: inherit;

      span::before,
      span::after {
        background-color: currentColor;
      }
    }

    &:hover,
    &:focus {
      color: $white;
      background-color: $main-color;
    }

    &:active {
      color: yellow;
    }
  }

  &__table-img {
    width: 150px;
    height: auto;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $screen-xl) {
      width: 100px;
    }
  }

  &__table-price {
    font-weight: 500;
  }

  &__table-sum {
    font-weight: 700;
  }

  &__result {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 12px;
    background-color: $white;
    box-shadow: $shadow;
    margin-bottom: 25px;

    @media (min-width: $screen-md) {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 35px;
    }

    @media (min-width: $screen-xl) {
      padding: 25px 50px;
    }

    .h4 {
      margin: 0;
      width: 100%;
      margin-bottom: 25px;
      font-weight: 500;

      @media (min-width: $screen-md) {
        width: auto;
        margin-bottom: 0;
      }
    }

    span:not(.basket__result-btn-wrapper) {
      display: inline-block;
      vertical-align: baseline;
      margin-left: 10px;
      font-weight: 700;
      color: $main-color;
    }
  }

  &__result-num {

  }

  &__result-btn-wrapper {
    width: 100%;
    text-align: center;

    @media (min-width: $screen-md) {
      width: auto;
    }
  }

  &__result-del {
    //width: 100%;
    font-size: 1em;
    text-decoration: underline;
    color: $second-color;
    user-select: none;
    cursor: pointer;
    background: none;
    border: none;

    &:hover,
    &:focus {
      color: $main-color;
    }
  }

  &__btns {

  }

  &__btn-col {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 5);
    @include col(lg, 4);
    //@include col(xl, 5);
    margin-bottom: 25px;

    @media (min-width: $screen-md) {
      margin-bottom: 0;
    }

    &:last-child {
      @include col-offset(md, 2);
      @include col-offset(lg, 4);
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    width: 100%;
    padding: 9px 15px 8px;

    @media (min-width: $screen-md) {
      width: 100%;
    }
  }
}
