.prod-filters-block {

  $block-name:                &; // #{$block-name}__element

  &__range-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-bottom: 30px;

    input[type=number] {
      @include field-text;
      width: 40%;
      min-height: 1.35em;
      padding-top: 5px;
      padding-bottom: 5px;
      text-align: center;
      border: none;
      border-bottom: 2px solid $icon-color;
    }
  }

  &__submit-wrapper {

    .btn {
      width: 100%;
    }
  }

  &__selected-filters {
    border-bottom: 2px solid $body-bg;
  }

  &__selected-filters-title {
    padding: 24px;
    font-size: 20px;
    font-weight: 500;
  }

  &__clear-filters {
    width: 100%;
    padding: 10px 24px;
    margin-bottom: 10px;
    font-size: 16px;
    color: $link-color;
    text-align: left;
    text-decoration: underline;
    white-space: nowrap;
    background: none;
    border: none;
    user-select: none;
    cursor: pointer;

    &:hover,
    &:focus {
      color: $link-color--hover;
    }

    &--disabled,
    &:disabled {
      text-decoration: none;
      color: $text-muted;
      pointer-events: none;
      cursor: default;
    }
  }
}
