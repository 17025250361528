.map {
  width: 100%;
  height: 240px;

  @media (min-width: $screen-md) {
    height: 360px;
  }

  @media (min-width: $screen-lg) {
    height: 100%;
  }

  $block-name:                &; // #{$block-name}__element

}
