@mixin field-text {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  min-height: calc(#{$line-height}em + #{$field-padding-vertical} + #{$field-padding-vertical} + 2px); // Привет, IE
  margin: 0;
  border-bottom: 2px solid $border-color;
  border-radius: $border-radius;
  padding: $field-padding-vertical  $field-padding-horizontal;
  line-height: $line-height;
  font-size: 1em;
  font-family: inherit;
  background-color: #fff;
  -moz-appearance: textfield; // stylelint-disable-line
  appearance: textfield;

  &:hover,
  &:focus {
    @include field-focus;
  }

  &[disabled],
  &[readonly] {
    cursor: not-allowed;
    background-color: $body-bg;
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration,
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none; // stylelint-disable-line  property-no-vendor-prefix
  }

  &[type='color'] {
    padding: 0;
  }

  &::placeholder {
    color: $text-muted;
    opacity: 1;
  }
}


@mixin field-help-text {
  display: block;
  padding-top: 0.4em;
  font-size: $font-size--sm;
  line-height: 1.2em;
  color: $error-color;

  &:empty { // stylelint-disable-line
    display: none;
  }
}


@mixin field-focus {
  outline: 0;
  border-color: $main-color;
  //box-shadow: 0 0 0 2px rgba($main-color, $opacity);
}


@mixin field-name {
  display: block;
  margin-bottom: 5px;
  line-height: $line-height;
  // font-weight: 700;
  font-weight: 400;
  color: $text-color;
}
