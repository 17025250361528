.field-search {
  // width: 100%;

  $block-name:                &; // #{$block-name}__element

  &__result-list {

  }

  &__item {
    padding: 10px;
    border-bottom: 2px solid $body-bg;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  &__res-name {
    flex: 0 0 50%;
    margin-right: 12px;
    font-weight: 700;
    color: $title-color;

    @media (min-width: $screen-lg) {
      flex-basis: 65%;
    }
    @media (min-width: $screen-xl) {
      flex-basis: 55%;
    }
  }

  &__res-price {
    flex: 1 1 auto;
    text-align: right;
  }

  &__res-sum,
  &__res-code {
    display: block;
  }

  &__res-sum {
    font-weight: 700;
  }

  &__res-code {
    font-size: 12px;
    color: $text-muted;
  }

}
