.brand-card {
  display: block;
  box-shadow: $shadow;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: $shadow--hover;
  }

  $block-name:                &; // #{$block-name}__element

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    padding: 20px;
    overflow: hidden;
    background-color: $white;

    @media (min-width: $screen-md) {
      height: 170px;
    }

    @media (min-width: $screen-lg) {
      height: 200px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &--nolink {
    &:hover,
    &:focus {
      box-shadow: $shadow;
    }
  }
}
