.copyrights {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  $block-name:                &; // #{$block-name}__element

  &__owner {
    font-size: $font-size--sm;
    color: $icon-color;
  }

  &__developer {
    opacity: 0.7;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
