// В этом файле должны быть стили для БЭМ-блока field-radio, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

$color-danger:                hsl(2, 64%, 58%) !default;

.field-radio {

  $block-name:                &; // #{$block-name}__element

  &__input-wrap--error {

    #{$block-name}__name-text,
    #{$block-name}__help-text {
      color: $color-danger;
    }
  }
}
