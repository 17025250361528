
$toggler-part-height:         2px !default;
$toggler-part-bg-color:       #000 !default;

.burger {

  $block-name:                &; // #{$block-name}__element

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 22px;
  height: 22px;
  user-select: none;
  cursor: pointer;

  > span {
    display: inline-block;
    width: 100%;
    height: $toggler-part-height;
    background: $toggler-part-bg-color;
    position: relative;
    transition: background $transition-time $transition-time;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: $toggler-part-height;
      background: $toggler-part-bg-color;
      transform-origin: 50% 50%;
      transition: top $transition-time $transition-time, transform $transition-time;
    }

    &:before {
      top: -10px;
    }

    &:after {
      top: 10px;
    }
  }

  &--close {

    > span {
      transition: background $transition-time 0s;
      background: transparent;

      &:before,
      &:after {
        transition: top $transition-time, transform $transition-time $transition-time;
        top: 0;
      }

      &:before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &:after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
