.logo {

  $block-name:                &; // #{$block-name}__element

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  img {
    display: block;
    width: 45px;
    height: 52px;

    @media (min-width: $screen-md) {
      width: 164px;
      height: 66px;
    }

    @media (min-width: $screen-lg) {
      width: 201px;
      height: 82px;
    }
  }

  &--footer {

    img {
      width: 150px;
      height: 63px;

      @media (min-width: $screen-md) {
        width: 168px;
        height: 70px;
      }

      @media (min-width: $screen-lg) {
        width: 186px;
        height: 78px;
      }
    }
  }
}
