@mixin text-hide {
  font-size: 0;
  line-height: 0;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

@mixin list-decorated {
  padding-left: 50px;
  position: relative;
  list-style-type: none;

  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-2px);
    left: 0;
    content: "";
    display: block;
    width: 30px;
    height: 2px;
    background-color: $main-color;

    @media (min-width: $screen-md) {
      transform: translateY(-3px);
    }
  }
}

@mixin img-sharp {
  image-rendering: pixelated;
   image-rendering: -moz-crisp-edges; /* Firefox */
   -ms-interpolation-mode: nearest-neighbor; /* IE */
}

@mixin mmenu-header {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: 700;
  line-height: 1.2;
  //color: $title-color !important;
}
