$gray-lightest:               hsl(0, 0%, 90%);
$border-color:                hsl(0, 0%, 60%) !default;

$line-height:                 1.375em !default;

$border-radius:               3px !default;

.field-select {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  //margin-bottom: 0.5em;

  $block-name:                &; // #{$block-name}__element

  &__name {
    @include field-name;
  }

  &__input-wrap {
    display: block;
  }

  &__select {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    line-height: $line-height;
    padding: $field-padding-vertical $field-padding-horizontal;
    font-size: inherit;
    font-family: inherit;
    border-bottom: 1px solid $border-color;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: $border-radius;
    appearance: none;
    background-color: #fff;
    background-image: svg-load('../blocks/field-select/img_to_bg/bottom-arrow-s.svg', fill=$text-muted);
    background-repeat: no-repeat;
    background-position: right 1em center;
    background-size: 15px 8px;
    box-shadow: $shadow;

    &[multiple] {
      background-image: none;
      min-height: 6.8em;
    }

    &::-ms-expand {
      display: none;
    }

    &:hover,
    &:active,
    &:focus {
      @include field-focus;
      background-image: svg-load('../blocks/field-select/img_to_bg/bottom-arrow-s.svg', fill=$main-color);
    }


    &[disabled],
    &[readonly] {
      cursor: default;
      background-color: $gray-lightest;
    }

    option {
      font-size: 1em;
    }
  }

  &__help-text {
    @include field-help-text;
  }

  &--sort {

    @media (min-width: $screen-md) {
      flex-direction: row;
      align-items: baseline;
    }

    #{$block-name}__name {

      @media (min-width: $screen-md) {
        margin-right: 25px;
      }
    }

    #{$block-name}__select-wrap {

      @media (min-width: $screen-md) {
        flex: 1 1;
      }

      @media (min-width: $screen-xl) {
        max-width: 300px;
      }
    }
  }
}
