.fields-group {

  $block-name:                &; // #{$block-name}__element

  display: flex;
  flex-wrap: wrap;
  //align-items: flex-start;
  margin-bottom: 0.75em;

  .btn,
  select,                     // обычно, не участвует в группировке
  input {
    position: relative;
    margin: 0;

    // &:hover,
    &:focus {
      position: relative;
      z-index: 1;             // фокус должен быть красивым
    }
  }

  .field-actions,
  // .field-file,                // обычно, не участвует в группировке
  // .field-select,              // обычно, не участвует в группировке
  .field-text {
    margin: 0;
  }

  .field-actions {

    &:not(:last-child) .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) .btn {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .btn {

      // &:hover,
      &:focus {
        z-index: 1;           // фокус должен быть красивым
      }
    }
  }

  .field-text {

    &:not(:last-child) .field-text__input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) .field-text__input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &--row {
    justify-content: space-between;
    margin-bottom: 0;

    & > div,
    & > a {
      width: 100%;
    }

    & > div {

      @media (min-width: $screen-md) {
        width: calc(60% - 5px);
      }
    }

    & > a {

      @media (min-width: $screen-md) {
        width: calc(40% - 5px);
      }
    }
  }

  &--btn-wrapper {
    margin: 25px 0;
  }

  // .field-file {               // обычно, не участвует в группировке

  //   input {
  //     position: absolute;
  //   }

  //   &:not(:last-child) .field-file__name-text {
  //     border-top-right-radius: 0;
  //     border-bottom-right-radius: 0;
  //   }

  //   &:not(:first-child) .field-file__name-text {
  //     border-left: 0;
  //     border-top-left-radius: 0;
  //     border-bottom-left-radius: 0;

  //     &:before {
  //       border-top-left-radius: 0;
  //       border-bottom-left-radius: 0;
  //     }
  //   }
  // }

  // .field-select {             // обычно, не участвует в группировке

  //   &:not(:last-child) .field-select__select {
  //     border-top-right-radius: 0;
  //     border-bottom-right-radius: 0;
  //   }

  //   &:not(:first-child) .field-select__select {
  //     border-left: 0;
  //     border-top-left-radius: 0;
  //     border-bottom-left-radius: 0;
  //   }
  // }
}
