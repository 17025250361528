$typo-margin-vertical: 10px !default;

.field-num {

  $block-name:                &; // #{$block-name}__element

  display: block;
  margin-bottom: $typo-margin-vertical;

  &__name {
    @include field-name;
  }

  &__input-wrap {
    display: block;
  }

  &__input-and-btns {
    position: relative;
    display: block;
    max-width: 140px;
  }

  &__input {
    @include field-text;
    width: 150px;
    padding: $field-padding-vertical 35px;
    font-size: 14px;
    font-weight: 500;
    color: $title-color;
    text-align: center;
    border: none;

    #{$block-name}:not(#{$block-name}--disabled) button:focus ~ & { // stylelint-disable-line
      @include field-focus;
    }
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: none;
    padding: 0;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    font: 0/0 a; // stylelint-disable-line
    color: transparent;
    background: transparent;

    &:hover,
    &:focus {
      //background-color: $main-color;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &--plus {
      right: 5px;
      background: svg-load('../blocks/sprite-svg/svg/plus.svg', fill=$title-color) center no-repeat;
      background-size: 8px 8px;

      &:hover,
      &:focus {
        background: $main-color svg-load('../blocks/sprite-svg/svg/plus.svg', fill=$white) center no-repeat;
      }
    }

    &--minus {
      left: 5px;
      background: svg-load('../blocks/sprite-svg/svg/minus.svg', fill=$title-color) center no-repeat;
      background-size: 8px 2.5px;

      &:hover,
      &:focus {
        background: $main-color svg-load('../blocks/sprite-svg/svg/minus.svg', fill=$white) center no-repeat;
      }
    }

    #{$block-name}--disabled & {
      cursor: not-allowed;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  &__help-text {
    @include field-help-text;
  }



  &--error {

    #{$block-name}__name,
    #{$block-name}__input,
    #{$block-name}__help-text {
      color: $color-danger;
    }

    #{$block-name}__input {
      border-color: $color-danger;
      background-color: lighten($color-danger, 35%);
    }
  }
}
