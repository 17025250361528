.contacts-block {

  @media (min-width: $screen-md) {
    background-color: $white;
    box-shadow: $shadow;
  }

  $block-name:                &; // #{$block-name}__element

  & > .row {
    background-color: $white;
    box-shadow: $shadow;

    @media (min-width: $screen-md) {
      background: none;
      box-shadow: none;
    }
  }

  &__text {
    padding-top: 25px;

    @media (min-width: $screen-md) {
      padding-bottom: 25px;
      padding-left: 25px;
      padding-right: 25px;
    }

    @media (min-width: $screen-xl) {
      padding-left: 50px;
    }

    .col-1-2 {
       margin-bottom: 0;
    }
  }

  &__title {
    padding-bottom: 25px;
    margin: 0;
    border-bottom: 2px solid $body-bg;
  }

  &__list {

  }

  &__item {
    margin-top: 15px;

    span {
      display: inline-block;
      vertical-align: baseline;
    }

    &--worktime {

      #{$block-name}__day-off {
        margin-left: 15px;

        @media (min-width: $screen-lg) {
          display: block;
          margin-left: 0;
        }
      }
    }
  }

  &__link {
    color: inherit;
  }
}
