.product-card {
  display: flex;
  //flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  //align-content: space-between;
  position: relative;
  height: 100%;
  background-color: $white;
  box-shadow: $shadow;

  &:hover,
  &:focus {
    //box-shadow: $shadow--hover;
  }

  $block-name:                &; // #{$block-name}__element

  h5 {
    margin-top: 0;
    color: currentColor;
  }

  .btn--favorits {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $icon-color;

    &:hover,
    &:focus {
      color: $white;
    }

    svg {
      fill: currentColor;
    }
  }

  &__link {
    display: block;
    width: 100%;
    padding: 20px 20px 15px;
    color: $title-color;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $main-color;
    }

    &--nopadding {
      padding: 0;
    }
  }

  &__img {

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__descr {
    width: 100%;
    padding: 10px 20px;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: $font-size--sm;

    p {
      max-height: 78px;
      overflow: hidden;
    }
  }

  &__price-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-top: auto;
  }

  &__price {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    width: 100%;
    padding: 0 20px;
    margin-bottom: 15px;
    font-size: 16px;
    color: $title-color;

    & > span {
      font-weight: 500;
    }
  }

  &__old-price {
    display: inline-block;
    vertical-align: baseline;
    margin-right: 15px;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through;
    color: $text-muted;
  }

  &__new-price {
    margin-right: 15px;
  }

  &__unit {
    flex-basis: 100%;
    display: inline-flex;
    width: 100%;
    margin-top: 6px;
    font-size: 12px;

    span {
      color: $text-muted;
    }
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    .field-num {
      position: relative;
      height: auto;
      margin-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
      // border-top: 2px solid $body-bg;

      &::before {
        display: block;
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $body-bg;
      }
    }
  }

  &__submit {
    position: relative;
    flex: 1 1;
    padding-left: 40px;
    padding-right: 20px;
    height: auto;
    font-size: 14px;
    font-weight: 500;
    color: $white;
    background-color: $main-color;
    //border: 2px solid $main-color;

    &::after {
      display: block;
      position: absolute;
      left: 18px;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 18px;
      height: 18px;
      background: svg-load('../blocks/sprite-svg/svg/basket.svg', fill=$white);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &:hover,
    &:focus {
      background-color: $main-color--hover;
    }
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
    width: auto;
    max-width: 150px;
    height: 35px;
    overflow: hidden;

    img {
      display: block;
      width: auto;
      height: 100%;
      max-height: 35px;
      object-fit: contain;
      object-position: center;
    }
  }

  &__special-mark {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px;
    left: 0;
    max-width: 150px;
    height: auto;
    pointer-events: none;

    span + span {
      margin-top: 15px;
    }
  }

  &__label {
    display: inline-block;
    padding: 6px 10px 6px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    user-select: none;

    &--new {
      color: $title-color;
      background: #FFD656;
    }

    &--offer {
      color: $white;
      background: #FF0019;
    }
  }

  &--row-style {

    @media (min-width: $screen-xl) {
      flex-direction: row;
    }

    .btn--favorits {

      &:hover,
      &:focus {

        @media (min-width: $screen-xl) {

          &::before {
            color: $main-color;
          }
        }
      }

      @media (min-width: $screen-xl) {
        top: 20px;
        right: 20px;

        &::before {
          position: absolute;
          display: block;
          content: "Добавить в Избранное";
          top: 16px;
          right: 55px;
          width: auto;
          font-size: 14px;
          font-weight: 400;
          text-align: right;
          user-select: none;
          white-space: nowrap;
        }
      }
    }

    #{$block-name}__link {

        @media (min-width: $screen-xl) {
          padding-top: 25px;
          padding-bottom: 25px;
        }
    }

    #{$block-name}__descr {

        @media (min-width: $screen-xl) {
          padding-bottom: 25px;
        }
    }

    #{$block-name}__price-block {

      @media (min-width: $screen-xl) {
        align-self: flex-end;
      }
    }

    #{$block-name}__price {

      @media (min-width: $screen-xl) {
        margin-bottom: 25px;
        text-align: right;
      }
    }

    #{$block-name}__logo {

      @media (min-width: $screen-xl) {
        top: 20px;
        left: 20px;
      }
    }

    #{$block-name}__special-mark {

      @media (min-width: $screen-xl) {
        top: 70px;
      }
    }
  }
}
