$gray-lightest:               hsl(0, 0%, 90%) !default;

$text-color:                  hsl(0, 0%, 10%) !default;
$text-color--muted:           hsl(0, 0%, 50%) !default;

$border-color:                hsl(0, 0%, 60%) !default;

$line-height:                 1.375em !default;

$field-padding-vertical:      0.3em !default;
$field-padding-horizontal:    0.7em !default;

.tabs {

  $block-name:                &; // #{$block-name}__element

  // margin-top: $line-height;
  // margin-bottom: $line-height;

  &__links {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //align-items: flex-end;

    @media (min-width: $screen-md) {
      justify-content: flex-start;
      padding-top: 15px;
      padding-left: 20px;
      padding-right: 20px;
      border-bottom: 2px solid $body-bg;
    }

    @media (min-width: $screen-xl) {
      padding: 15px 25px 0;
    }

    .no-js & {
      display: none;
    }

    /* &:after {
      content: '';
      flex-grow: 1;
      height: 1px;
      background-color: $border-color;
    } */
  }

  &__link-wrap {
    //flex-grow: 1;
    position: relative;
    width: calc(50% - 7px);
    margin-bottom: 15px !important;

    @media (min-width: $screen-md) {
      width: auto;
      margin-bottom: 0 !important;
    }

    &:not(:last-child) {
      border-right: none;
    }

    &--active {

      &::after {

        @media (min-width: $screen-md) {
          display: block;
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          bottom: -3px;
          width: 100%;
          height: 4px;
          background-color: $main-color;
        }
      }

      #{$block-name}__link {
        color: $white;
        background-color: $main-color;

        @media (min-width: $screen-md) {
          color: $title-color;
          background-color: $white;
          box-shadow: none;
        }
      }
    }
  }

  &__link {
    display: block;
    //$font-size: 12px;
    font-size: 12px;
    line-height: $font-size * 2;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {

      @media (min-width: $screen-md) {
        color: $title-color;
        background-color: $white;
        box-shadow: none;
      }
    }

    @media (min-width: $screen-sm) {
      font-size: 14px;
    }

    @media (min-width: $screen-md) {
      padding-top: 5px;
      padding-bottom: 7px;
      padding-left: 25px;
      padding-right: 25px;
      font-size: 18px;
      border: none;
      color: $text-muted;
    }

    @media (min-width: $screen-xl) {
      padding-bottom: 12px;
    }
  }

  &__content-wrapper {
    display: block;
    padding: 15px 10px 15px;

    @media (min-width: $screen-md) {
      padding: 20px 20px 25px;
    }

    @media (min-width: $screen-xl) {
      padding: 40px 25px 25px;
    }
  }

  &__content-item {

    h3 {
      margin-top: 0;
    }

    .js & {
      display: none;
    }

    &--active {

      .js & {
        display: block;
      }
    }
  }

  &__col-left {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 4);
    margin-bottom: 15px;

    @media (min-width: $screen-md) {
      margin-bottom: 0;
    }

    &--equal {
      @include col(md, 6);
    }
  }

  &__col-right {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 8);

    &--equal {
      @include col(md, 6);
    }
  }

  &__col-gallery {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 4);
    margin-bottom: 15px;

    @media (min-width: $screen-md) {
      margin-bottom: 25px;
    }
  }

  &__col-review {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 6);
    @include col(lg, 7);
  }

  &__col-review-form {
    @include col(xs, 12, $grid-columns, 20px);
    @include col(md, 6);
    @include col-offset(lg, 1);
    @include col(lg, 4);
  }
}

