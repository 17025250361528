// Базовые цвета https://colorscheme.ru/color-converter.html

$black:                       hsl(0, 0%, 0%);
$gray-darkest:                hsl(0, 0%, 10%);
$gray-darker:                 hsl(0, 0%, 20%);
$gray-darken:                 hsl(0, 0%, 30%);
$gray-dark:                   hsl(0, 0%, 40%);
$gray:                        hsl(0, 0%, 50%);
$gray-light:                  hsl(0, 0%, 60%);
$gray-lighten:                hsl(0, 0%, 70%);
$gray-lighter:                hsl(0, 0%, 80%);
$gray-lightest:               hsl(0, 0%, 90%);
$white:                       hsl(0, 0%, 100%);

$color-vk:                    #4a76a8;
$color-fb:                    #4267b2;
$color-tw:                    #40A2F5;
$color-gh:                    #24292e;

// Семантические цвета

$main-color:                  #03a49b;
$color-main:                  $main-color;
$main-color--hover:           #15c9bf;
$second-color:                #16334f;
$error-color:                 #ff4c49;

$title-color:                 #303030;
$text-color:                  #515155;
$text-muted:                  #999999;

$icon-color:                  #cdcdcd;

$body-bg:                     #f5f5f5;
$header-top-bg:               #f0f0f0;
$body-dark:                   $second-color;

$link-color:                  $main-color;
$link-color--hover:           $main-color--hover;

$border-color:                $text-muted;

// Базовая типографика

$font-size:                   16px;
$font-size--mob:              14px;
$font-size--h1:               42px;
$font-size--h1-mob:           32px;
$font-size--h2:               38px;
$font-size--h2-mob:           28px;
$font-size--h3:               30px;
$font-size--h3-mob:           24px;
$font-size--h4:               20px;
$font-size--h4-mob:           18px;
$font-size--h5:               16px;
$font-size--h5-mob:           14px;
$font-size--h6:               14px;
$font-size--h6-mob:           12px;

//$font-size--xs:               12px;
$font-size--sm:               12px;


$line-height:                 1.35;

$typo-margin-vertical:        1em;

$font-family:                 -apple-system, BlinkMacSystemFont, 'Rubik', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', 'Arial', sans-serif;
$font-family--headings:       $font-family;



// Ширины

$screen-xs:                   0;
$screen-sm:                   480px;
$screen-md:                   768px;
$screen-lg:                   992px;
$screen-xl:                   1280px;
$screen-xxl:                  1800px;

$container-sm:                100%;
$container-md:                100%;
$container-lg:                $screen-lg - 20;
$container-xl:                $screen-xl - 20;
$container-xxl:               $screen-xxl - 20;



// Модульная сетка

$grid-columns:                12;
$grid-gutter-width:           25px;



// Разное

$field-padding-vertical:      10px;
$field-padding-horizontal:    12px;

$border-btn:                  2px solid $main-color;
$border-block:                2px solid $body-bg;
$border-radius:               0;
$opacity:                     0.7;
$transition-time:             0.15s;
$shadow:                      2px 2px 8px rgba(0, 0, 0, 0.15);
$shadow--hover:               1px 1px 20px rgba(0, 0, 0, 0.45);
$shadow--header:              0px 4px 6px rgba(0, 0, 0, 0.25);

$menu-desktop-width:          $screen-lg;
$toggler-part-height:         2px;
$toggler-part-bg-color:       #000;

$z-index-modal:               100;
