$line-height:                 1.375em !default;

$text-color:                  hsl(0, 0%, 10%) !default;

.breadcrumbs {
  padding-top: 25px;
  margin-bottom: 25px;

  $block-name:                &;
  // #{$block-name}__element

  &__list {
    padding: 0;
    list-style: none;
    margin: 0;
  }


  &__item {
    display: inline-block;
    vertical-align: baseline;
    max-width: 100%;
    margin-right: 0.6em;
    margin-bottom: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $screen-md) {
      margin-bottom: 0;
    }

    &:not(:last-child)::after {
      content: '|';
      display: inline-block;
      margin-left: 0.8em;
      font-weight: 700;
      color: $text-muted;
    }

    &:last-child {

      #{$block-name}__link {
        text-decoration: none;
        color: $main-color;
      }
    }
  }

  &__link {
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: $text-muted;
    text-decoration: none;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: $main-color;
      //text-decoration: none;
    }
  }
}
