.seo-text-block {
  margin-top: 30px;
  margin-bottom: 30px;

  @media (min-width: $screen-md) {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  $block-name:                &; // #{$block-name}__element
}
