// В этом файле должны быть стили для БЭМ-блока loader, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

$gray-lighter:                hsl(0, 0%, 80%) !default;
$gray-lightest:               hsl(0, 0%, 90%) !default;

.loader {

  $block-name:                &; // #{$block-name}__element

  display: inline-block;

  &:before {
    content: '';
    display: block;
    width: 1em;
    height: 1em;
    border: 3px solid $gray-lightest;
    border-top: 3px solid $gray-lighter;
    border-radius: 50%;
    animation: loader-rotate 1s linear infinite;
  }
}



@keyframes loader-rotate {

  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
