$text-muted:           hsl(0, 0%, 50%) !default;

$font-size--sm:        0.75em !default;

$line-height:          1.375em !default;

.field-actions {

  $block-name:                &; // #{$block-name}__element

  display: block;
  margin-top: 1.5em;
  // margin-bottom: 1em;

  .btn {
    width: 100%;
    margin-bottom: 1em;
  }

  &__text {
    display: block;
    // margin-top: ($line-height / 2);
    // margin-bottom: ($line-height / 2);
    // font-size: $font-size--sm;
    color: $text-muted;
    line-height: $line-height;
  }
}
