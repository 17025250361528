.rating {
  margin: 0 0 1em 0;
  padding: 0;
  border: none;

  $block-name:                &; // #{$block-name}__element

  &__inner {
    display: flex;
    align-items: center;
  }

  &__caption {
    margin-right: 14px;
    font-size: 14px;

    @media (min-width: $screen-xl) {
      font-size: 16px;
    }
  }

  &__group {
    position: relative;
    width: 155px;
    height: 16px;
    background-size: 31px 16px;
    background-position: center;
    background-repeat: repeat-x;
    background-image: svg-load('../blocks/rating/bg-img/star-space.svg', fill=$icon-color);
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:focus {
      & ~ #{$block-name}__focus {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        //z-index: -1;
        outline: 2px solid $main-color;
        outline-offset: 3px;
      }
    }
  }

  &__star {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    //width: 31px;
    height: 16px;
    background-size: 31px 16px;
    background-repeat: repeat-x;
    cursor: pointer;
  }

  &__star:hover,
  &__input:checked + #{$block-name}__star {
    background-image: svg-load('../blocks/rating/bg-img/star-space.svg', fill=$main-color);
  }

  &__star:hover ~ #{$block-name}__star {
    background-image: svg-load('../blocks/rating/bg-img/star-space.svg', fill=$icon-color);
  }

  /* Options */

  &__star:nth-of-type(1) {
    z-index: 5;
    width: 31px;
  }

  &__star:nth-of-type(2) {
    z-index: 4;
    width: 31px * 2;
  }

  &__star:nth-of-type(3) {
    z-index: 3;
    width: 31px * 3;
  }

  &__star:nth-of-type(4) {
    z-index: 2;
    width: 31px * 4;
  }

  &__star:nth-of-type(5) {
    z-index: 1;
    width: 31px * 5;
  }
}
