// В этом файле должны быть стили для БЭМ-блока table, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, $media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

$gray-lightest:               hsl(0, 0%, 90%) !default;

$text-color--muted:           hsl(0, 0%, 50%) !default;

$border-color:                hsl(0, 0%, 60%) !default;

$font-size--small:            0.75em !default;

$line-height:                 1.375em !default;

$field-padding-vertical:      0.3em !default;
$field-padding-horizontal:    0.7em !default;

.table {

  $block-name:                &; // #{$block-name}__element

  border-collapse: collapse;
  width: 100%;
  border: 1px solid $border-color;
  margin: $line-height 0;

  caption {
    padding-top: 0.4em;
    text-align: right;
    caption-side: bottom;
    font-size: $font-size--small;
    line-height: 1em;
    color: $text-color--muted;
  }

  td,
  th {
    padding: $field-padding-vertical $field-padding-horizontal;
    vertical-align: top;
    border-bottom: 1px solid $border-color;

    &:not(:first-child) {
      border-left: 1px solid $border-color;
    }
  }

  th {
    text-align: left;
  }

  &__header {}

  &__body {}

  &__footer {}
}
