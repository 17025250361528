// В этом файле должны быть стили для БЭМ-блока field-file, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

$color-danger:                hsl(2, 64%, 58%) !default;

.field-file {

  $block-name:                &; // #{$block-name}__element

  &--error {

    #{$block-name}__name,
    #{$block-name}__name-text,
    #{$block-name}__input,
    #{$block-name}__help-text {
      color: $color-danger;
    }

    #{$block-name}__name-text {
      border-color: $color-danger;
      background-color: lighten($color-danger, 35%);
    }
  }
}
