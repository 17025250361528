.review-form {

  $block-name:                &; // #{$block-name}__element

  &__title {
    margin-top: 0;
    margin-bottom: 16px;
  }

  &__users-review {
    margin: 0;
    padding: 0;
    border: none;
  }
}
