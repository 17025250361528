$line-height:                 1.375em !default;

.field-checkbox {

  $block-name:                &; // #{$block-name}__element

  display: block;
  margin-bottom: 0.5em;

  &__title {
    display: block;
    font-weight: 700;
    line-height: $line-height;
    margin-bottom: 0.4em;
  }

  &__input-wrap {

    & + & {
      margin-top: 0.5em;
    }
  }

  &__name {
    position: relative;
    display: block;
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 65px;
    padding-right: 25px;
    font-weight: 400;
    line-height: $line-height;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $body-bg;

      #{$block-name}__name-text {
        color: $main-color;
      }
    }

    /* &.selected {
      background-color: $body-bg;
    } */
  }

  &__name-text {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    // свой чекбокс с картинкой

    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 25px;
      // transform: translateY(-55%);
      width: 22px;
      height: 22px;
      background-image: svg-load('../blocks/field-checkbox/img_to_bg/checkbox.svg', fill=$icon-color);
      background-size: 100%;
    }

    @at-root input:checked ~ &:before {
      background-image: svg-load('../blocks/field-checkbox/img_to_bg/checkbox--checked.svg', fill=$main-color);
    }

    & > span {
      font-weight: 400;
      color: $text-muted;
    }

    &--bold {
      font-size: 14px;
      font-weight: 500;

      &:before {
        top: 6px;
      }
    }
  }

  &__input {
    position: absolute;
    top: 0.7em;
    left: 0;
    padding: 0;
    margin: 0;
    transform: translateY(-50%);

    &:focus,
    &:active {
      @include field-focus;
    }

    // сокрытие инпута в случае использования своего чекбокса
    opacity: 0;
  }

  &__help-text-wrap {
    padding-left: 1.5em;
  }

  &__help-text {
    @include field-help-text;
  }
}
