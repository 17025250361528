// В этом файле должны быть стили для БЭМ-блока table-mobile-scroll, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

$line-height:                 1.375em !default;

$border-color:                hsl(0, 0%, 60%) !default;

.table-mobile-scroll {

  $block-name:                &; // #{$block-name}__element

  display: block;
  width: 100%;
  overflow-x: auto;
  margin-top: $line-height;
  margin-bottom: $line-height;
  border: 1px solid $border-color;

  table {
    margin: 0;
    border: 0 !important; // stylelint-disable-line  declaration-no-important
    width: 100%;
  }

  tbody,
  thead,
  tfoot {

    &:last-child {

      tr:last-child {

        // stylelint-disable  max-nesting-depth, selector-max-compound-selectors
        td,
        th {
          border-bottom: 0;
        }
        // stylelint-enable
      }
    }
  }

  caption {
    text-align: left;
    padding: 0.4em;
    border-bottom: 1px solid $border-color;
    caption-side: top;
  }
}
