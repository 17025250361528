$toggler-part-height:         3px !default;
$toggler-part-bg-color:       #000 !default;

.close {

  $block-name:                &; // #{$block-name}__element

  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 20px;
  height: 20px;
  user-select: none;
  cursor: pointer;

  > span {
    display: inline-block;
    width: 100%;
    height: $toggler-part-height;
    background: transparent;
    position: relative;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $toggler-part-height;
      background: $toggler-part-bg-color;
      transform-origin: 50% 50%;
    }

    &:before {
      transform: rotate3d(0, 0, 1, 45deg);
    }

    &:after {
      transform: rotate3d(0, 0, 1, -45deg);
    }
  }
}
