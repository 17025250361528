.sf-menu {
  // box-shadow: 15px 25px 55px rgba(0, 0, 0, 0.5);

  $block-name:                &; // #{$block-name}__element

}

/*** essential styles ***/
.sf-menu, .sf-menu * {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.sf-menu li {
  position: relative;
}
.sf-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  //z-index: 99;
}
.sf-menu > li {
  float: left;
}
.sf-menu li:hover > ul,
.sf-menu li.sfhover > ul {
  display: block;
}

.sf-menu a {
  display: block;
  //position: relative;
  cursor: pointer;
}
.sf-menu ul ul {
  top: 0;
  left: 100%;
}


/*** demo skin ***/
.sf-menu {
  float: left;
  margin-bottom: 1em;
}
.sf-menu ul {
  box-shadow: 2px 2px 6px rgba(0,0,0,.2);
  min-width: 12em; /* allow long menu items to determine submenu width */
  *width: 12em; /* no auto sub width for ie7, see white-space comment below */
}
.sf-menu a {
  // border-left: 1px solid #fff;
  // border-top: 1px solid #dfeeff; /* fallback colour must use full shorthand */
  border-top: 1px solid $body-bg;
  padding: 15px 25px;
  text-decoration: none;
  zoom: 1; /* ie7 */

  &:first-child {
    border: none;
  }
}
.sf-menu a {
  color: inherit;
}
.sf-menu li {
  background: $white;
  white-space: nowrap; /* no need for supersubs plugin */
  *white-space: normal; /* ...unless you support ie7 (let it wrap) */
  -webkit-transition: background .2s;
  transition: background .2s;
}
.sf-menu ul li {
  background: $white;
}
.sf-menu ul ul li {
  background: $white;
}
.sf-menu li:hover,
.sf-menu li.sfhover {
  /* only transition out, not in */
  -webkit-transition: none;
  transition: none;
}

.sf-menu > li:hover > a,
.sf-menu > li:focus > a {
  background: $main-color;
  color: $white;
}

/*** arrows (for all except ie7) **/
.sf-arrows .sf-with-ul {
  padding-right: 2.5em;
  *padding-right: 1em; /* no css arrows for ie7 (lack pseudo-elements) */
}
/* styling for both css and generated arrows */
/* .sf-arrows .sf-with-ul:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1em;
  margin-top: -3px;
  height: 0;
  width: 0;
  order of following 3 rules important for fallbacks to work
  border: 5px solid transparent;
  border-top-color: #dfeeff; edit this to suit design (no rgba in ie8)
  border-top-color: rgba(255,255,255,.5);
} */
/* .sf-arrows > li > .sf-with-ul:focus:after,
.sf-arrows > li:hover > .sf-with-ul:after,
.sf-arrows > .sfhover > .sf-with-ul:after {
  border-top-color: white; ie8 fallback colour
} */
/* styling for right-facing arrows */
/* .sf-arrows ul .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #dfeeff; edit this to suit design (no rgba in ie8)
  border-left-color: rgba(255,255,255,.5);
} */
/* .sf-arrows ul li > .sf-with-ul:focus:after,
.sf-arrows ul li:hover > .sf-with-ul:after,
.sf-arrows ul .sfhover > .sf-with-ul:after {
  border-left-color: white;
}
 */
/* adding sf-vertical class in addition to sf-menu creates a vertical menu */
/* eg. <ul class="sf-menu sf-vertical"> ... */
.sf-vertical {
  z-index: 10;
  position: relative;
  margin-bottom: 0;

  min-width: 12em;
  width: 100%;
  /* if you want the width of the closed menu to expand to its
  widest top-level menu item (like its "supersubs" submenus do),
  replace the width rule above with the following two rules. */

  /*
  *width: 12em;
  */
}
.sf-vertical ul {
  left: 100%;
  top: 0;
}
.sf-vertical > li {
  //position: static;
  position: relative;

  float: none;
}
.sf-vertical li {
  width: 100%;
}

/*** alter arrow directions ***/
/* .sf-vertical.sf-arrows > li > .sf-with-ul:after {
  margin-top: -5px;
  margin-right: -3px;
  border-color: transparent;
  border-left-color: #dfeeff; edit this to suit design (no rgba in ie8)
  border-left-color: rgba(255,255,255,.5);
}
.sf-vertical.sf-arrows li > .sf-with-ul:focus:after,
.sf-vertical.sf-arrows li:hover > .sf-with-ul:after,
.sf-vertical.sf-arrows .sfhover > .sf-with-ul:after {
  border-left-color: white;
} */

.sf-vertical__item-link,
.sf-vertical__catalog-link {
  border-top: none !important;
}

.sf-vertical__item.sfHover {

  .sf-vertical__item-link::after {
    background-image: svg-load('../blocks/sprite-svg/svg/right-arrow-s.svg', fill=$white);
  }
}

.sf-vertical__item-link {
  position: relative;

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    transform: translatey(-50%);
    right: 15px;
    width: 8px;
    height: 15px;
    background-image: svg-load('../blocks/sprite-svg/svg/right-arrow-s.svg', fill=$main-color);
  }

  &:hover,
  &:focus {

    &::after {
      background-image: svg-load('../blocks/sprite-svg/svg/right-arrow-s.svg', fill=$white);
    }
  }
}

.sf-vertical__catalog-list {
}

.sf-vertical__catalog-item {
  //padding-bottom: 20px;

  a {
    padding: 15px 45px;

    &:hover,
    &:focus {
      background: $main-color;
      color: $white;
    }
  }
}

.sf-vertical__catalog-link {
  margin-top: 0;
}
