// В этом файле должны быть стили для БЭМ-блока field-toggler, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

$gray-lightest:               hsl(0, 0%, 90%);
$border-color:                hsl(0, 0%, 60%) !default;

$line-height:                 1.375em !default;

.field-toggler {

  $block-name:                &; // #{$block-name}__element

  display: block;
  margin-bottom: ($line-height / 2);

  &__title {
    @include field-name;
  }

  &__input-wrap {

    & + & {
      margin-top: ($line-height / 2);
    }
  }

  &__name {
    position: relative;
    display: inline-block;
    padding-left: 3.2em;
    margin-right: 0.6em;
    font-weight: 400;
    line-height: $line-height;
  }

  &__name-text {

    &:before {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0;
      width: px-to-em(35);
      height: px-to-em(18);
      transform: translateY(-50%);
      background-color: #fff;
      border: 1px solid $border-color;

      @at-root input:focus ~ & {
        @include field-focus;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 2px;
      width: px-to-em(14);
      height: px-to-em(14);
      transform: translateY(-50%);
      background-color: $gray-lightest;
      border: 1px solid $border-color;
      transition: all 0.3s;
    }

    @at-root input:checked ~ &:after {
      transform: translate(px-to-em(17), -50%);
    }
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  &__help-text-wrap {
    padding-left: 1.5em;
  }

  &__help-text {
    @include field-help-text;
  }
}
