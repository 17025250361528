/* rubik-regular - cyrillic_latin */
@font-face {
  font-display: fallback;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local('Rubik'), local('Rubik-Regular'),
       url('../fonts/rubik-v8-cyrillic_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/rubik-v8-cyrillic_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-italic - cyrillic_latin */
@font-face {
  font-display: fallback;
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: local('Rubik Italic'), local('Rubik-Italic'),
       url('../fonts/rubik-v8-cyrillic_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/rubik-v8-cyrillic_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-500 - cyrillic_latin */
@font-face {
  font-display: fallback;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local('Rubik Medium'), local('Rubik-Medium'),
       url('../fonts/rubik-v8-cyrillic_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/rubik-v8-cyrillic_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* rubik-700 - cyrillic_latin */
@font-face {
  font-display: fallback;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local('Rubik Bold'), local('Rubik-Bold'),
       url('../fonts/rubik-v8-cyrillic_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/rubik-v8-cyrillic_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



body {
  // ТОЛЬКО типографика!
  font-family: $font-family;
  font-size: $font-size--mob;
  line-height: $line-height;
  text-align: left;
  word-break: break-word;
  color: $text-color;
  background-color: $body-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: $screen-lg) {
    font-size: $font-size;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.3em;
  margin-bottom: 0.55em;
  font-family: $font-family;
  font-weight: 700;
  line-height: 1.2;
  color: $title-color;
}

.h1,
h1 {
  margin-top: 1.1em;
  margin-bottom: 0.55em;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {

  & > a {
    color: inherit;

    &:hover,
    &:focus {
      color: $main-color;
      text-decoration: none;
    }
  }
}

.h1,
h1 {
  font-size: $font-size--h1-mob;

  @media (min-width: $screen-lg) {
    font-size: $font-size--h1;
  }
}

.h2,
h2 {
  font-size: $font-size--h2-mob;

  @media (min-width: $screen-lg) {
    font-size: $font-size--h2;
  }
}

.h3,
h3 {
  font-size: $font-size--h3-mob;

  @media (min-width: $screen-lg) {
    font-size: $font-size--h3;
  }
}

.h4,
h4 {
  font-size: $font-size--h4-mob;

  @media (min-width: $screen-lg) {
    font-size: $font-size--h4;
  }
}

.h5,
h5 {
  font-size: $font-size--h5-mob;

  @media (min-width: $screen-lg) {
    font-size: $font-size--h5;
  }
}

.h6,
h6 {
  font-size: $font-size--h6-mob;

  @media (min-width: $screen-lg) {
    font-size: $font-size--h6;
  }
}

.p,
p,
ul,
ol,
dl,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: currentColor;
}

p + p,
ul + p,
ol + p {
  margin-top: 0.5em;
  margin-bottom: 0;
}

address {
  font-style: normal;
}

ul,
ol {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 1.2em;

  ul,
  ol {
    margin-top: 0;
    margin-bottom: 0;
  }
}

li {
  line-height: inherit;
}

.small,
small {
  font-size: $font-size--sm;
  font-weight: 400;
}

sub,
sup {
  font-size: 70%;
}

b,
strong {
  font-weight: bolder;
}

abbr[title] {
  text-decoration: underline dotted;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}
