.brands-logo-block {
  padding: 20px 10px 10px;
  background-color: $white;

  $block-name:                &; // #{$block-name}__element

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: space-between;
  }

  &__item {
    flex: 0 1;
    flex-basis: calc(25%);
    padding: 0 6px;
    margin-bottom: 15px;


    @media (min-width: $screen-sm) {
      flex-basis: calc(20%);
    }

    @media (min-width: $screen-md) {
      flex-basis: calc( 100% / 7 );
      padding: 0 12px;
    }

    @media (min-width: $screen-lg) {
      flex-basis: calc( 100% / 8 );
    }

    @media (min-width: $screen-xl) {
      flex-basis: calc( 100% / 10 );
    }

    &:last-child {
      margin-right: auto;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    img {
      display: block;
    }
  }
}
