.brand-intro {
  margin-bottom: 25px;

  $block-name:                &; // #{$block-name}__element

  &__title {

    @media (min-width: $screen-md) {
      margin-top: 0;
    }
  }

  &__quantity {
    display: inline-block;
    vertical-align: baseline;
    margin-left: 25px;
    font-size: 14px;
    font-weight: 400;
    color: $text-muted;

    & > span {
      font-weight: 500;
    }

    @media (min-width: $screen-xl) {
      font-size: 16px;
    }
  }
}
