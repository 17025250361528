.category-block {
  height: 100%;
  padding: 15px;
  background-color: $white;
  box-shadow: $shadow;

  @media (min-width: $screen-md) {
    padding: 20px;
  }

  @media (min-width: $screen-md) {
    padding-left: 25px;
    padding-right: 25px;
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: $shadow--hover;
  }

  $block-name:                &; // #{$block-name}__element

  &__link {
    display: flex;
    flex-direction: column;
    color: $title-color;

    &:hover,
    &:focus {
      color: $main-color;
      text-decoration: none;
    }
  }

  &__img-wrapper {
    max-height: 100px;
    overflow: hidden;

    img {
      margin: 0 auto;
      object-fit: contain;
      object-position: center;
    }
  }

  &__title {
    margin-bottom: 0;
    color: currentColor;
  }
}
