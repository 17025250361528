$border-color:                hsl(0, 0%, 60%) !default;

$line-height:                 1.375em !default;

$screen-xs:                   0 !default;
$screen-sm:                   480px !default;
$screen-md:                   768px !default;
$screen-lg:                   992px !default;
$screen-xl:                   1280px !default;
$screen-xxl:                  1800px !default;

.table-responsive {
  width: 100%;

  $block-name:                &;
  // #{$block-name}__element

  @media (max-width: ($screen-xl - 1)) {
    display: block;
    border: 0;

    caption {
      display: block;
      width: 100%;
      text-align: left;
    }

    thead {
      display: none;
    }

    tbody {
      display: block;
      width: 100%;

      tr {
        display: block;
        //border: 1px solid $border-color;
        border-bottom: 0;

        &:not(:last-child) { // stylelint-disable-line  max-nesting-depth
          //margin-bottom: $line-height + em;
        }
      }

      td {
        display: inline-block;
        vertical-align: baseline;
        width: 100%;
        border-right: 0 !important; // stylelint-disable-line  declaration-no-important
        border-left: 0 !important; // stylelint-disable-line  declaration-no-important

        &:before { // stylelint-disable-line  max-nesting-depth
          display: inline-block;
          vertical-align: baseline;
          font-weight: 700;
          content: attr(data-label);
        }
      }
    }
  }
}
