// В этом файле должны быть стили для БЭМ-блока field-range, его элементов,
// модификаторов, псевдоселекторов, псевдоэлементов, @media-условий...
// Очередность: http://nicothin.github.io/idiomatic-pre-CSS/#priority

$gray-lightest:               hsl(0, 0%, 90%) !default;
$border-color:                hsl(0, 0%, 60%) !default;

$line-height:                 1.375em !default;

$border-radius:               3px !default;

.field-range {

  $block-name:                &; // #{$block-name}__element

  display: block;
  margin-bottom: ($line-height / 2);

  &__name {
    @include field-name;
  }

  &__input-wrap {
    position: relative;
  }

  &__input {
    align-self: center;
    padding: 0;
    width: 100%;
    height: 2em;
    border-radius: 0.25rem;
    border: 0;
    background: none;
    font-size: inherit;
    cursor: pointer;
    appearance: none;

    &::-webkit-slider-runnable-track,
    &::-webkit-slider-thumb {
      -webkit-appearance: none; // stylelint-disable-line  property-no-vendor-prefix
    }

    &:focus {
      @include field-focus;
    }



    // Фоновая линия ползунка

    // Да, да, и это — локальная примесь.
    @mixin field-range-runnable-track {
      box-sizing: border-box;
      width: 100%;
      height: 0.75em;
      border-radius: $border-radius;
      background: #fff;
      border: 1px solid $border-color;
    }

    // Увы, тут похожие наборы свойств приходится писать порознь.
    &::-webkit-slider-runnable-track {
      @include field-range-runnable-track; // локальная примесь!
    }

    &::-moz-range-track {
      @include field-range-runnable-track; // локальная примесь!
    }

    &::-ms-track {
      @include field-range-runnable-track; // локальная примесь!
      color: transparent;
    }


    // Смещающийся элемент ползунка

    // Да, да, и это — локальная примесь.
    @mixin field-range-slider-thumb {
      box-sizing: border-box;
      padding: 0;
      width: 2em;
      height: 2em;
      border-radius: 1em;
      box-shadow: none;
      cursor: ew-resize;
      background: $gray-lightest;
      border: 1px solid $border-color;
    }

    &::-webkit-slider-thumb {
      margin-top: -0.68em;
      @include field-range-slider-thumb; // локальная примесь!
    }

    &::-moz-range-thumb {
      @include field-range-slider-thumb; // локальная примесь!
    }

    &::-ms-thumb {
      @include field-range-slider-thumb; // локальная примесь!
    }

    &::-ms-fill-lower,
    &::-ms-tooltip {
      display: none;
    }
  }

  &__help-text {
    @include field-help-text;
    padding-top: 0;
  }
}

