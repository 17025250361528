.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    padding-top: percentage(9 / 16); // по умолчанию ожидается вставка в формате 16/9
  }

  // embed,
  img,
  iframe,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }



  &--4-3 { // модификатор на случай вставки 4/3

    &::before {
      padding-top: percentage(3 / 4);
    }
  }
}
