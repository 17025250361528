$gray-lighter:                hsl(0, 0%, 80%) !default;
$border-color:                hsl(0, 0%, 60%) !default;

$text-color--muted:           hsl(0, 0%, 50%) !default;

$line-height:                 1.375em !default;

$field-padding-vertical:      0.3em !default;
$field-padding-horizontal:    0.7em !default;

$border-radius:               3px !default;

.field-text {

  $block-name:                &; // #{$block-name}__element

  display: block;
  width: 100%; // N
  margin-bottom: 1.5em;

  &__name {
    @include field-name;
  }

  &__input-wrap {
    display: block;
  }

  &__input {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    // height: 2.125em;
    padding: $field-padding-vertical  $field-padding-horizontal;
    margin: 0;
    line-height: $line-height;
    font-size: 1em;
    font-family: inherit;
    background-color: $white;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 2px solid $border-color;
    border-radius: $border-radius;
    appearance: textfield;

    &:hover,
    &:focus {
      @include field-focus;
    }

    &[disabled],
    &[readonly] {
      cursor: default;
      background-color: $gray-lighter;
    }

    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-decoration {
      -webkit-appearance: none; // stylelint-disable-line  property-no-vendor-prefix
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      // height: auto;
    }

    &[type='color'] {
      // max-width: 100px;
      padding: 0;
    }

    &::placeholder {
      color: $text-muted;
      opacity: 1;
    }

    @at-root textarea#{&} {
      $textarea-height: 50px !default;
      height: auto;
      min-height: calc(#{$textarea-height} + 2px);
      resize: vertical;
    }
  }

  &__help-text {
    @include field-help-text;
  }
}
