.btn {

  $block-name:                &; // #{$block-name}__element

  box-sizing: border-box;
  display: inline-block;
  min-width: 38px;
  min-height: 38px;
  margin: 0;
  padding: 5px;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  text-align: center;
  font-size: $font-size;
  font-family: inherit;
  font-weight: 700;
  line-height: $line-height;
  text-decoration: none;
  text-transform: none;
  color: $main-color;
  border-radius: $border-radius;
  border: $border-btn;
  background-color: $white;
  background-image: none;
  cursor: pointer;

  @media (min-width: $screen-xl) {
    min-width: 42px;
    min-height: 42px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: $white;
    background-color: $main-color;
    border-color: $main-color;
  }

  &:active,
  &:focus {
    box-shadow: $shadow;
  }

  &--disabled,
  &:disabled {
    // opacity: $opacity;
    cursor: not-allowed;
    color: $text-muted;
    background-color: $gray-lightest;
    border-color: $gray-lighter;

    @at-root a.btn--disabled {
      pointer-events: none;
    }
  }

  &--icon {
    position: relative;
    color: $main-color;
    border: none;

    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: auto;
      fill: currentColor;
    }

    &--disabled {
      cursor: not-allowed;
      color: $text-muted;
      background-color: $gray-lightest;
      border-color: $gray-lighter;

      &:hover,
      &:focus {
        color: $text-muted;
        background-color: $gray-lightest;
        border-color: $gray-lighter;
      }
    }
  }

  &--main {
    position: relative;
    padding-top: 9px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 45px;
    text-align: left;
    color: $white;
    background-color: $main-color;
    border-color: $main-color;

    &::after {
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 13px;
      height: 15px;
      background: svg-load('../blocks/sprite-svg/svg/right-arrow.svg', fill=$white, stroke=rgba(255, 255, 255, 0));
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &:hover,
    &:focus {
      //color: $main-color;
      background-color: $main-color--hover;
      border-color: $main-color--hover;
    }

    &--disabled,
    &:disabled {
      color: $text-muted !important;
      background-color: $body-bg !important;
      border-color: $body-bg !important;
    }
  }

  &--outline {
    position: relative;
    min-width: 42px;
    min-height: 42px;

    @media (min-width: $screen-md) {
      width: calc(50% - 12px);
      padding-left: 25px;
      padding-right: 25px;
    }

    &--disabled,
    &:disabled {
      color: $text-muted !important;
      background-color: $body-bg !important;
      //border-color: $body-bg !important;

      &::after {
        background: none;
      }
    }

    &:hover,
    &:focus {

      &::after {
        background: svg-load('../blocks/sprite-svg/svg/right-arrow.svg', fill=$white);
      }
    }

    &::after {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 13px;
      height: 15px;
      background: svg-load('../blocks/sprite-svg/svg/right-arrow.svg', fill=$main-color);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    & > span {
      display: none;

      @media (min-width: $screen-md) {
        display: inline-block;
        vertical-align: middle;
        transform: translateY(2px);
      }
    }

    &--left {

      @media (min-width: $screen-md) {
        text-align: right;
      }

      &::after {
        left: 12px;
        transform: translateY(-50%) rotate(180deg);

        @media (min-width: $screen-md) {
          left: 15px;
        }
      }
    }

    &--right {

      @media (min-width: $screen-md) {
        text-align: left;
      }

      &::after {
        right: 12px;

        @media (min-width: $screen-md) {
          right: 15px;
        }
      }
    }
  }

  &--readmore {
    position: relative;
    padding-top: 9px;
    padding-bottom: 8px;
    padding-left: 30px;
    padding-right: 45px;
    text-align: left;
    color: $title-color;
    background-color: $white;
    border-color: $white;

    &::after {
      display: block;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      width: 13px;
      height: 15px;
      background: svg-load('../blocks/sprite-svg/svg/right-arrow.svg', fill=$main-color, stroke=rgba(255, 255, 255, 0));
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    &:hover,
    &:focus {
      color: $main-color;
      background-color: $white;
      border-color: $white;
    }
  }

  &--crumbs {
    font-weight: 500;
    line-height: 28px;
    color: $text-color;
    border: none;

    @media (min-width: $screen-xl) {
      line-height: 32px;
    }
  }
}
