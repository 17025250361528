.calculator-form {
  padding: 25px 10px;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: 25px;
  background-color: $white;
  box-shadow: $shadow;

  @media (min-width: $screen-md) {
    padding: 25px 20px;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: $screen-lg) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (min-width: $screen-xl) {
    padding-left: 50px;
    padding-right: 50px;
  }

  $block-name:                &; // #{$block-name}__element

  .field-select {

    &--mb {
      margin-bottom: 1.5em;
    }
  }

  &__title {
    padding-bottom: 20px;
    margin-top: 0;
    margin-bottom: 25px;
    border-bottom: 2px solid $body-bg;

    @media (min-width: $screen-md) {
      padding-bottom: 25px;
      margin-bottom: 30px;
    }
  }

  &__intro {
    margin-bottom: 25px;
    font-weight: 500;

    @media (min-width: $screen-md) {
      margin-bottom: 30px;
    }
  }

  &__actions {
    justify-content: flex-end;

    & > .btn--outline {
      flex: 1 1 auto;
      max-width: 175px;

      &::after {
        display: none;
      }
    }
  }

  &__result {

    h4 {
      margin-top: 0;
      margin-bottom: 25px;
    }

    .field-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: 40px;
    }

    .btn {
      width: 100%;
      margin-bottom: 0;

      @media (min-width: $screen-md) {
        max-width: 240px;
      }
    }
  }

  &__list-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    //justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;

    dt {
      flex-basis: 60%;

      @media (min-width: $screen-lg) {
        flex-basis: 70%;
      }
    }

    dd {
      flex: 1 1;
      margin-left: 0;
      font-weight: 700;
    }

    &--img {
      align-items: flex-start;
    }
  }

  &__img-wrapper {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;

    img {
      width: auto;
      height: 100%;
      max-width: 196px;
    }

    &--res {
      img {
        width: 100%;
        height: auto;
        max-width: 196px;
      }
    }
  }
}
