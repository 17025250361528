.calculator-item {
  padding: 0 0 25px;

  @media (min-width: $screen-md) {
    padding: 0 20px 25px;
    background-color: $white;
    box-shadow: $shadow;
  }

  @media (min-width: $screen-lg) {
    padding-left: 25px;
    padding-right: 25px;
  }

  @media (min-width: $screen-xl) {
    padding: 0 50px 25px;
  }

  $block-name:                &; // #{$block-name}__element

  &__title {
    padding: 25px 0;
    margin-top: 0;
    margin-bottom: 25px;
    border-bottom: 2px solid $body-bg;
  }
}
