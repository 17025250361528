
/**
 * Преобразование пикселей в em на основе переменной с базовым размером шрифта
 * (указывается в px) проекта.
 * @param  {number} $px Число без единиц измерения, кол-во пикселей
 * @param  {string} $font-size Число пикселей, размер конт. шрифта на проекте
 * @return {string} Результат преобразования $px в em на основе $font-size
 */

$font-size:                   15px !default;

@function px-to-em($px, $font-size: $font-size) {
  @return (($px * 100 / ($font-size / 1px)) / 100) * 1em;
}
